import React from "react"
import { graphql } from "gatsby"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Emoji from "../components/emoji"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AdsCard from "../components/adsCard"

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'

import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'

const GalleryPage = ({ data, location }) => {
  const classes = useStyles()

  var rowCount = 0
  const rowNum = 4
  return (
    <Layout location={location} title="Work">
      <SEO
        title={`Photo Gallery`}
        description="元々写真を取るのが好きでしたが、最近、ミラーレス一眼のカメラを購入しました。今まで下田の美しい海・ビーチ・大自然を撮った写真をＵＰしています。転載希望の場合、ご一報下されば、使っていただいても大丈夫です。"
        slug="/gallery/"
      />
      <Container maxWidth="md" >
        <div className={classes.root}>
          <h1 className={classes.title}>
            <PhotoAlbumIcon className={classes.icon}/><span className={classes.about}>Gallery</span>
            <Typography variant="body2" component="div">
                元々写真を取るのが好きでしたが、最近、ミラーレス一眼のカメラ<Emoji emoji="📷" label="camera" />を購入しました。今まで撮った写真をＵＰしています。転載希望の場合、ご一報下されば、使っていただいても大丈夫です。トリミングはOKですが、画像の上に文字を乗せる、色を変えるなどはNGです。悪意ある（と思われるような）ご利用はNGです。
            </Typography>
          </h1>
          <GridList cellHeight={165} className={classes.gridList} cols={rowNum}>
            {data.allFile.edges.map(({node}) => {
              let cols = Math.ceil( Math.random() * 2)
              rowCount += cols
              if (rowCount >= rowNum ) {
                if (rowCount > rowNum) {
                  cols = rowCount - rowNum
                }
                rowCount = 0
              }

              return(
                <GridListTile key={node.name} cols={cols} className={classes.listTile}>
                  <Zoom>
                    <img className={classes.img} src={node.childImageSharp.fluid.src} alt={node.name} />
                  </Zoom>
                </GridListTile>
              )
            })}
          </GridList>
        </div>
        <AdsCard slot="5723982973" />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/photo/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: `theme.palette.background.gray`,
  },
  listTile: {
    margin: 0,
    padding: 0
  },
  gridList: {
    width: `100%`,
    height: `auto`,
  },
  title: {
    textAlign: `center`,
  },
  about: {
    verticalAlign: `top`
  },
  icon: {
    fontSize: 40,
  },
  img: {
    width: `100%`,
    height: `200px`,
    objectFit: `cover`,
  }
}))

export default GalleryPage
